import styled, { css, keyframes } from "styled-components"

const fadeIn = keyframes`
  from {
    opacity: 0
  }

  to {
    opacity:1
  }
`

const Container = styled.div`
  width: 100%;

  padding-bottom: 71px;

  padding: 0 21px;
  ${({ pad }) =>
    pad &&
    css`
      min-height: 649.53px;
      @media (max-width: 1024px) {
        min-height: 442.94px;
      }
      @media (max-width: 768px) {
        min-height: 801.78px;
      }
      @media (max-width: 768px) {
        min-height: 801.78px;
      }
      @media (max-width: 400px) {
        min-height: 600px;
      }
      /* padding-top: 1px; */
    `}

  ${({ header }) =>
    header &&
    css`
    position:fixed;
    box-shadow: 0 1px 12px 0 rgba(195, 203, 222, 0.59);
    z-index:100;
    /* animation: ${fadeIn} 0.15s linear; */
    background-color: #F8FAFE;
    `}
`
export default Container
