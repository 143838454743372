import styled from "styled-components"

const Img = styled.img`
  width: 132px;
  margin: 0;
  height: 34px;
  @media (max-width: 768px) {
    width: 110px;
    height: 28px;
  }
`
export default Img
