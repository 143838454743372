import React from "react"
export const basics = [
  {
    title: "What is Cryptocurrency?",
    content:
      "Cryptocurrency is digital  form of money like the Naira and other currencies, except there are no bills to be carried around.",
  },
  {
    title: "Crypto vs Fiat money",
    content:
      "Cryptocurrency and fiat money are stores of value that can be used as a medium of exchange. Neither has any real value on its own. A big difference between them is fiat is backed by a government and hence its supply is determined by that government.",
  },
  {
    title: "Advantages of Crypto over Fiat money",
    content: (
      <span>
        <ol>
          <li>
            <b>No Inflation:</b>
            <br />
            <br />
            Fiat money is prone to inflation over time, a million Naira does not
            have the same buying power today that it did ten years ago. The
            issuing government can print more notes whenever necessary and
            increase the supply of the currency in circulation thereby
            depreciating the value of the currency. For cryptocurrency, the
            total amount of the currency that will ever be in circulation is
            specified by an algorithm from the beginning so it is impossible to
            inflate its supply. Its value does not decrease over time, only
            market factors can affect its value.
            <br />
          </li>

          <li>
            <b>Global mobility: </b>
            <br />
            <br />
            Cryptocurrency facilitates cross-border payments faster and easier
            than fiat money can ever hope to. Using fiat for cross-border
            transactions requires that you give the bank private information and
            you have to rely on them to set up the transaction. With crypto,
            there are no borders and international transactions are
            instantaneous.
            <br />
          </li>

          <li>
            <b>Privacy: </b>
            <br />
            <br />
            Currently, the central banks have control over every information
            passed during a fiat transaction. Whenever you initiate a wire
            transfer or payment, the banks know exactly how much you are sending
            and who you are sending it to. With Crypto, the transaction details
            are still made public in the ledger, but all personal information is
            kept private and secure.
            <br />
          </li>
        </ol>
      </span>
    ),
  },
  {
    title: "Crypto vs Fiat: Conclusion",
    content: (
      <table>
        <thead>
          <tr>
            <th />
            <th>Crypto</th>
            <th>Fiat</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>What is it?</td>
            <td data-th="Crypto">
              Digital currency that runs on computer codes
            </td>
            <td data-th="Fiat">Local currency backed by the government</td>
          </tr>
          <tr>
            <td>What determines its value?</td>
            <td data-th="Crypto">The basic laws of supply and demand.</td>
            <td data-th="Fiat">Trust in the government that issued it.</td>
          </tr>
          <tr>
            <td>Security</td>
            <td data-th="Crypto">Guaranteed by a network of computers</td>
            <td data-th="Fiat">Secured by banks and governments</td>
          </tr>
          <tr>
            <td>Buying Power</td>
            <td data-th="Crypto">Depends on merchants who accept it</td>
            <td data-th="Fiat">Can buy but only in the country of issue </td>
          </tr>
          <tr>
            <td>Physical bills or coins?</td>
            <td data-th="Crypto">No</td>
            <td data-th="Fiat">Yes</td>
          </tr>
        </tbody>
      </table>
    ),
  },
  {
    title: "About Bitcoin Mining",
    content: (
      <span>
        The term “mining” brings to mind digging out or extracting minerals like
        Gold from the earth. Although this is not the case with Bitcoin, the
        term is still used because Bitcoin exists in the protocol (just like
        Gold exists underground) it just hasn’t been “mined” yet. The Bitcoin
        protocol was designed such that only 21 million Bitcoin will ever exist
        and what the miners do is bring them out.
        <br />
        <br />
        Let’s get a bit technical:
        <br />
        <br />
        The Bitcoin network is kept secure by the mining operation and this is
        also how transactions are validated. New blocks are found in the
        blockchain every now and again and added to the chain. The miners get
        Bitcoins as a sort of reward for this since so much energy is expended
        during this process as not all attempts at finding a new block is
        successful.
        <br />
        <br />
        This energy expended known as “Proof of work” ensures that bitcoins
        can’t be created out of thin air. They have to be earned and also, the
        network is kept secure in that if a hacker tries to change a transaction
        that already occurred, he has to undo all the work that has been done on
        the network till date. This is quite impossible and hence the network is
        kept secure.
        <br />
        <br />
        Finding a new block is like making a random guess for a lucky number.
        Each time a miner attempts this, a small amount of energy is used up and
        if this attempt isn’t successful, that energy is wasted. Statistically,
        a miner succeeds every ten minutes.
        <br />
        <br />
        All miners on the network are trying to guess what this mystery number
        is and only the successful miner gets the reward. The reward is
        currently 12.5 bitcoins and is halved every four years, the next one
        being 2020/21.
      </span>
    ),
  },
]

export const more = [
  {
    title: "Bitcoin (BTC)",
    content:
      "First introduced in 2008 via a whitepaper, bitcoin which some people say is similar to gold (as there is a limited supply)  is the first decentralized cryptocurrency. Just like the internet, it isn’t controlled by any single entity so anyone can use it at will. Bitcoin is very easy to divide into smaller units, easy to trade with and can be stored. It is a faster, easier and more secure way of moving and storing money.",
  },
  {
    title: "Ethereum (ETH)",
    content:
      "Ethereum, created in 2014 is very similar to bitcoin is a blockchain that was designed to build and run smart contracts. (Smart contracts help you exchange money, property, or other items of value in a transparent, conflict-free way while avoiding the services of a middleman). The major difference between Bitcoin and Ethereum is in the type of data stored. ",
  },
  {
    title: "Litecoin (LTC)",
    content:
      "Litecoin was released in 2011. It is also similar to bitcoin, although, blocks are generated faster and there is a greater maximum number of coins.",
  },
  {
    title: "Stellar Lumens (XLM)",
    content:
      "Lumens is a digital asset created on the stellar network. It works just like Bitcoin and acts as a bridge between currencies where there isn’t a large direct market.",
  },
  {
    title: "SureRemit (RMT)",
    content:
      "RMT is a utility token built on the stellar network.  It was designed to be used by immigrants and customers within the SureRemit ecosystem.",
  },
  {
    title: "Dash",
    content:
      "Dash was designed to allow transactions to be processed quickly and to have a swift governance structure to overcome the shortfalls of bitcoin.",
  },
]

export const about = [
  {
    title: "Why Cryptocurrency?",
    content: (
      <span>
        Crypto is at the moment the safest, easiest and has the potential to
        become the most universally accepted way to exchange value.
        <br />
        <br />
        Potentially, any two people in the world, regardless of location can
        exchange money directly without the involvement of a financial
        institution or government. This would mean that people without access to
        traditional forms of money like banks and credit cards can also send and
        receive value.
        <br />
        <br />
        With cryptocurrency, we see a great reduction in the transaction fees
        usually charged by traditional financial institutions.
        <br />
        <br />
        Additionally, blockchain technology which powers cryptocurrency has a
        whole catalogue of usage which includes: verification of identity,
        validation of ownership, management of healthcare records and also the
        advancement of medical research.
      </span>
    ),
  },
  {
    title: "How is the value of a cryptocurrency fixed?",
    content: (
      <span>
        The value of a cryptocurrency is determined by the basic laws of supply
        and demand. The higher the demand for the cryptocurrency, the higher the
        price, and the lower the demand is, the lower the price will be. <br />
        <br />
        Also, the higher the supply, the lower the price, and the lower the
        supply, the lower the price.
      </span>
    ),
  },
  {
    title: "What Can You Buy With Crypto?",
    content: (
      <span>
        Due to the extreme volatility of crypto and increasing transaction fees,
        some outlets that were very enthusiastic about accepting it as payment
        very early on have discontinued this. Nevertheless, there is still a
        variety of stores (online and offline) where you can spend your crypto
        most especially Bitcoin.
        <br />
        <br />
        Crypto allows for fast and easy cross-border transactions.
        <br />
        <br />
        You can pay for flights, book hotels with your crypto. Platforms that
        let you book flights and hotels with your crypto include CheapAir,
        Destinia and Bitcoin.travel.
        <br />
        <br />
        Some gift card platforms accept bitcoin as payment. These include
        eGifter and Gyft. The gift cards can then be used to buy at any of the
        merchant locations worldwide (depending on the gift card chosen). You
        can also donate to your favorite causes as some of them now accept
        crypto payments.
        <br />
        <br />
        Microsoft app store also accepts bitcoin for downloading games, movies
        and other app-related services.You can use your crypto to buy gold,
        furnish your home, buy pizza or even pay your school fees.
      </span>
    ),
  },
]

export const tips = [
  {
    title: "Legality",
    content: (
      <span>
        In recent times, the crypto community has come under more intense
        scrutiny by governments and governing financial bodies.
        <br />
        <br />
        Notwithstanding all the price movements and the rapid emergence of new
        tokens, there is a burgeoning interest in cryptocurrencies in several
        circles. As a result, new regulations are being passed to oversee the
        use and trade of digital currencies.
        <br />
        <br /> However, very few countries have gone as far to outrightly
        declare trading in crypto illegal within their borders, although its
        status as a commodity differs from country to country.
        <br />
        <br /> Countries which consider trade in cryptocurrency as illegal
        include: Algeria, Egypt, Morocco, Bolivia, Colombia, Ecuador, Saudi
        Arabia, Iran, Bangladesh, Pakistan, Nepal, China, Taiwan, Cambodia, and
        Indonesia.
        <br />
        <br />
        Other countries and financial bodies have urged their citizens and the
        general public to exercise extreme caution in trading and/or investing
        in crypto.
        <br />
        <br />
        In Nigeria, banks are prohibited from all bitcoin and other virtual
        currency transactions. A committee has been set up by the Central Bank
        to look into the adoption of blockchain technology.
      </span>
    ),
  },
  {
    title: "Anonymity",
    content: (
      <span>
        Some cryptocurrencies are anonymous, but the vast majority of
        cryptocurrencies have records of all transactions which are publicly
        available and can be used by experts to trace the transactions back to
        their original owners. This helps safeguard against fraudulent
        practices. Crypto exchanges usually require ID verification before
        transactions can take place.
      </span>
    ),
  },
]

export const trading = [
  {
    title: "Buying Cryptocurrency",
    content: (
      <span>
        Now you know what cryptocurrency is! Excited? Well, the next step is to
        discover how to obtain some for yourself.
        <br />
        <br />
        You can either buy crypto directly from other people who already have
        some or from an exchange. Paying for the crypto is very easy as you can
        pay cash or with your debit or credit card. Wiring the money to the
        seller is also an option or better still pay with other
        cryptocurrencies. Of course, all these options depend on your
        geographical location and where you are buying the crypto from.
        <br />
        <br />
        Now that we know how to buy cryptocurrency, it is equally important to
        know how to store the cryptocurrency you are about to purchase.
        <br />
        <br /> The first step is to set up a wallet. A crypto wallet is just
        like your bank account where you can buy, sell, store and send your
        cryptocurrency to another wallet account. A wallet could be online: as
        part of a crypto exchange, or an independent wallet service provider, or
        offline: usually a hardware device or paper wallet. An offline wallet is
        more widely known as <i>cold storage</i>.<br />
        <br />
        There are several wallet services to choose from and you should do some
        research to know the right type of wallet for your crypto as not all
        wallets support every cryptocurrency in circulation.
        <br />
        <br /> Now, you can decide whether you want to buy from an exchange like
        Busha or from another individual. Exchanges usually require some form of
        identification when you are setting up an account. This is as a result
        of KYC (know-your-client) regulations meant to curb fraudulent
        activities and keep everyone safe.
        <br />
        <br />
        Most exchanges usually charge fees when you buy or sell bitcoin on their
        platform. These fees are negligible when compared with the fees banks
        and other traditional financial institutions charge for transactions.
        <br />
        <br />
        These transactions are usually completed in minutes or sometimes even
        hours depending on the network.
      </span>
    ),
  },
  {
    title: "Selling Your Cryptocurrency",
    content: (
      <span>
        Nowadays, it is just as easy to sell your crypto as it is to purchase
        it. All exchanges allow you to buy and sell. Your choice of the exchange
        to use depends on what type of holder you are: an institutional
        investor, a small investor or a trader.
        <br />
        <br />
        Some exchanges are targeted at big-time institutional investors and
        traders while others cater to small investors. Every exchange has a
        different user interface and some offer secure storage services.
        Usually, a valid means of identification is required before any
        transaction can occur. If you do sell your crypto, we advise that you
        pay the tax on any profit made from the sale.
        <br />
        <br />
        You can sell your crypto easily on Busha. <br />
        <br />
        If you do not want to sell for your crypto for cash, you can also swap
        one cryptocurrency for another. Currently, on
        <a href="https://busha.co"> Busha</a> you can swap your Bitcoin for
        Ethereum and vice versa.
        <br />
        <br />
        It is also possible to sell your bitcoin directly to your family and
        friends who have a crypto wallet. You can get paid for the sale via
        direct deposits or wire transfer to your bank account.
        <br />
        <br />
        Another option is to sell and collect cash payment though this is not a
        advisable especially when dealing with strangers.
      </span>
    ),
  },
  {
    title: "Storing your cryptocurrency",
    content: (
      <span>
        You are going to need a “place” to store the cryptocurrency you’re about
        to own. This place is referred to as a wallet. A wallet does not
        actually hold your crypto but instead, it holds the private key that
        grants you access to your crypto address, for example, a Bitcoin
        address, Ethereum address, etc.
        <br />
        <br />
        Depending on the design of the wallet, it can actually look like it
        contains your actually crypto which makes it easier for you to carry out
        transactions. A wallet can hold several keys and most investors have
        more than one wallet. Your wallet can be on your computer, mobile
        device, other storage gadget or even on a piece of paper.
      </span>
    ),
  },
  {
    title: "Deciding which Cryptocurrency to buy",
    content: (
      <div>
        On Busha, you can buy Bitcoin (BTC), Ethereum (ETH), Stellar Lumens
        (XLM), Litecoin (LTC), and SureRemit (RMT). Making a decision about
        which crypto to buy all depends on what you intend to use the crypto
        for. If you want to use crypto to primarily to purchase items, you can
        consider buying BTC as it is more widely accepted.
        <br />
        <br /> If you want to use it to send value (remittance), you can take a
        look at SureRemit which was created specifically for that purpose.
      </div>
    ),
  },
]

export const volatility = [
  {
    title: "What Can You Buy With Crypto?",
    content: (
      <span>
        Due to the extreme volatility of crypto and increasing transaction fees,
        some outlets that were very enthusiastic about accepting it as payment
        very early on have discontinued this. Nevertheless, there is still a
        variety of stores (online and offline) where you can spend your crypto
        most especially Bitcoin.
        <br />
        <br />
        Crypto allows for fast and easy cross-border transactions.
        <br />
        <br />
        You can pay for flights, book hotels with your crypto. Platforms that
        let you book flights and hotels with your crypto include CheapAir,
        Destinia and Bitcoin.travel.
        <br />
        <br />
        Some gift card platforms accept bitcoin as payment. These include
        eGifter and Gyft. The gift cards can then be used to buy at any of the
        merchant locations worldwide (depending on the gift card chosen). You
        can also donate to your favorite causes as some of them now accept
        crypto payments.
        <br />
        <br />
        Microsoft app store also accepts bitcoin for downloading games, movies
        and other app-related services. You can use your crypto to buy gold,
        furnish your home, buy pizza or even pay your school fees. SureRemit has
        a platform that lets you send purchase gift cards with your RMT token
        and send to your loved ones. They can then use the gift card to buy from
        approved merchants or pay for services.
      </span>
    ),
  },
  {
    title: "About Bitcoin Mining",
    content: (
      <span>
        The term “mining” brings to mind digging out or extracting minerals like
        Gold from the earth. Although this is not the case with Bitcoin, the
        term is still used because Bitcoin exists in the protocol (just like
        Gold exists underground) it just hasn’t been “mined” yet. The Bitcoin
        protocol was designed such that only 21 million Bitcoin will ever exist
        and what the miners do is bring them out.
        <br />
        <br />
        Let’s get a bit technical:
        <br />
        <br />
        The Bitcoin network is kept secure by the mining operation and this is
        also how transactions are validated. New blocks are found in the
        blockchain every now and again and added to the chain. The miners get
        Bitcoins as a sort of reward for this since so much energy is expended
        during this process as not all attempts at finding a new block is
        successful.
        <br />
        <br />
        This energy expended known as “Proof of work” ensures that bitcoins
        can’t be created out of thin air. They have to be earned and also, the
        network is kept secure in that if a hacker tries to change a transaction
        that already occurred, he has to undo all the work that has been done on
        the network till date. This is quite impossible and hence the network is
        kept secure.
        <br />
        <br />
        Finding a new block is like making a random guess for a lucky number.
        Each time a miner attempts this, a small amount of energy is used up and
        if this attempt isn’t successful, that energy is wasted. Statistically,
        a miner succeeds every ten minutes.
        <br />
        <br />
        All miners on the network are trying to guess what this mystery number
        is and only the successful miner gets the reward. The reward is
        currently 12.5 bitcoins and is halved every four years, the next one
        being 2020/21.
      </span>
    ),
  },
]

export const wallets = [
  {
    title: "Software Wallet",
    content: (
      <span>
        This is easily installed on your computer and helps you secure your
        private keys. It is usually free and has an easy-to-use interface. The
        downside to this is that if the computer is stolen or irreparably
        damaged, and your private keys are not stored elsewhere, then your
        crypto will be lost forever. It is advised to backup your software
        wallet on more than one device.
        <br />
        <br />
        If your computer is hacked and the hacker gets hold of your wallet and
        private keys, they can steal your crypto. Hence software wallets require
        a greater security precaution. Some common examples of software wallets
        are: Electrum, Exodus and Jaxx.
      </span>
    ),
  },
  {
    title: "Online Wallet",
    content: (
      <span>
        Online wallets are cloud-based and are usually more convenient to use
        than software wallets. You can access your private keys from any device
        so long as you know your password so there’s no need to have backups.
        They usually have desktop and mobile apps which allow for ease of access
        to send or receive crypto.
        <br />
        <br />
        The disadvantage of this is the lower security as you have to rely on
        the security protocol of the host and trust that they won’t close down
        or abscond with your crypto. Most online wallets are usually attached to
        exchanges and <a href="https://busha.co"> Busha</a> is one of such.
        <br />
        <br />
        If your computer is hacked and the hacker gets hold of your wallet and
        private keys, they can steal your crypto. Hence software wallets require
        a greater security precaution. Some common examples of software wallets
        are: Electrum, Exodus and Jaxx.
        <br />
        <br />
        Some exchanges also offer offline storage options.
      </span>
    ),
  },
  {
    title: "Mobile Wallet",
    content: (
      <span>
        This can be downloaded on your phone and mobile devices as apps and are
        particularly useful if you want to use your crypto to pay for something
        in a shop or if you trade crypto on the go. Most desktop wallets and all
        online wallets have mobile versions.
        <br />
        <br />
        <a href="https://busha.co"> Busha</a> has a mobile app which can be
        downloaded on both Android and iOS.
      </span>
    ),
  },
  {
    title: "Hardware Wallet",
    content: (
      <span>
        A hardware wallet is a small device on which your private keys are
        stored. It is very secure as it is generally offline (only connects to
        the internet to enable transactions) and therefore not hackable.
        However, due to its small size, it is very easy to lose or can be
        stolen. If this happens the private keys along with the crypto it
        contains will be lost forever.
        <br />
        <br />
        Some people with large crypto investments store their hardware wallets
        in bank vaults for safe keeping.
      </span>
    ),
  },
  {
    title: "Paper Wallet",
    content: (
      <span>
        This is perhaps the simplest type of wallet that exists. As the name
        implies, it is just a piece of paper on which public and private keys
        are printed. It is ideal for long-term storage away from the elements
        and also for gifting crypto. It is very secure as it isn’t connected to
        any network but at the same time very easy to lose.
        <br />
        <br />
        You can use WalletGenerator to easily create a new address and then
        print it out. Send some crypto to this address and store this piece of
        paper in a secure location.
      </span>
    ),
  },
]

export const links = [
  {
    title: "Crypto basics",
    link: "/#crypto_basics",
  },
  {
    title: "Common Cryptocurrencies",
    link: "/#common_cryptocurrencies",
  },
  {
    title: "More About Crypto",
    link: "/#more_about_crypto",
  },
  {
    title: "Safety Tips",
    link: "/#safety_tips",
  },
  {
    title: "Trading Crypto",
    link: "/#trading_crypto",
  },
  {
    title: "Crypto Wallets",
    link: "/#crypto_wallets",
  },
]
