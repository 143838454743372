import styled from "styled-components"

const Button = styled.a`
  background-color: #67c462;
  border: 1px solid #67c462;
  border-radius: 2px;
  min-width: 151px;
  height: 36px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.07px;
  line-height: 19px;
  text-align: center;
  cursor: pointer;
  outline: white;
  position: relative;
  transition: all 0.25s ease-in-out 0s;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  &::before {
    content: "";
    position: absolute;
    top: 5px;
    border: 1px solid #67c462;
    left: -5px;
    border-radius: 2px;
    width: 100%;
    height: 100%;
  }
  &:hover {
    transform: translateY(-1px);
    box-shadow: rgba(50, 50, 93, 0.1) 0px 7px 14px,
      rgba(0, 0, 0, 0.08) 0px 3px 6px;
  }
`
export default Button
