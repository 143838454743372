import styled from "styled-components"

import P from "../../elements/p"

const ImageText = styled(P)`
  color: #697788;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.08px;
  line-height: 29px;
  text-align: left;
  padding: 6px 12.5px;
  border-left: 1px solid rgba(105, 119, 136, 0.5);
  margin: 0;
  margin-left: 12px;
  @media (max-width: 768px) {
    padding: 4px 10.5px;
    font-size: 21px;
  }
`
export default ImageText
