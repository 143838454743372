import styled from "styled-components"
import H1 from "../../elements/H1"

const Title = styled(H1)`
  color: #1b314b;
  font-size: 48px;
  font-weight: 700;
  letter-spacing: -0.5px;
  line-height: 56px;
  max-width: 476px;
  width: 100%;
  text-align: left;
  @media (max-width: 768px) {
    max-width: 100%;
    font-size: 40px;
  }
`
export default Title
