import React from "react"

import SVG from "react-inlinesvg"
import styled, { css } from "styled-components"

export const Container = styled.div`
  display: flex;
  width: 100%;
  background-color: transparent;
  min-height: 329px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px;
  z-index: 1;
  position: relative;
`

export const Title = styled.h2`
  color: #212b36;
  font-family: "MarkPro";
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 18px;
  margin-top: 0;
`

export const SubTitle = styled.h3`
  color: #6a7986;
  font-family: "MarkPro";
  font-size: 19px;
  font-weight: 500;
  line-height: 23px;
  text-align: center;
  margin: 0;
`
export const ButtonContainer = styled.div`
  margin-top: 35px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Button = styled.a`
  color: #ffffff;
  background: #67c462;
  border-radius: 3px;
  border: 1px solid #67c462;
  transition: 0.4s ease-in;
  cursor: pointer;
  font-family: "MarkPro";
  outline: none;
  border: none;
  width: 119px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  text-decoration: none;
  text-decoration: none;
  line-height: 20px;
  &:hover {
    background: #5ba957;
    color: #ffffff;
  }
  ${props =>
    props.primary &&
    css`
      width: 151px;
      height: 50px;
    `};

  ${({ special }) =>
    special &&
    css`
      @media (max-width: 513px) {
        width: 100%;
        max-width: 313px;
        margin-bottom: 20px;
      }
    `}
`
const ButtonContainera = styled.div`
  display: flex;
  width: ${({ customWidth }) => customWidth || "100%"};
  .app-buttons {
    display: flex;
    width: 325px;
  }
  @media (max-width: 513px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    .app-buttons {
      width: 100%;
      justify-content: center;
      align-items: center;
    }
  }
`

const MobileAppButton = styled.a`
  border: 1px solid rgb(34, 34, 34);
  border-radius: 3px;
  padding: 9px 15px;
  max-width: 151px;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 10px;
  text-decoration: none;
  .isvg {
    display: block;
    height: 30.62px;
    width: auto;
    margin-right: 9px;
    max-width: 25px;
    svg {
      height: 100%;
      width: 100%;
      g {
        fill: rgb(34, 34, 34);
      }
    }
  }
  .text-container {
    display: flex;
    flex-direction: column;

    .big {
      color: rgb(34, 34, 34);
      font-family: "MarkPro";
      font-size: 14px;
      font-weight: 800;
      line-height: normal;
    }
    .small {
      color: rgb(34, 34, 34);
      font-family: "MarkPro";
      font-weight: 800;
      font-size: 10px;
      margin-bottom: 2px;
      line-height: normal;
    }
  }
  transform: translateY(0px);
  transition: all 0.1s ease-in-out;
  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.01), 0 3px 6px rgba(0, 0, 0, 0.08);
  }
  @media (max-width: 513px) {
    margin-left: ${({ noMargin }) => (noMargin ? "0" : "10px")};
    .text-container {
      .big {
        font-size: 12px;
      }
      .small {
        font-size: 9px;
      }
    }
  }
`

const AppButton = ({ logo, store, to, noMargin }) => (
  <MobileAppButton href={to} noMargin={noMargin}>
    <SVG src={logo} />
    <div className="text-container">
      <span className="small">Get it on</span>
      <span className="big">{store}</span>
    </div>
  </MobileAppButton>
)

export const ButtonCollection = ({ customWidth }) => (
  <ButtonContainera customWidth={customWidth}>
    <Button
      primary="true"
      href="https://busha.co/dashboard"
      special="true"
      target="_blank"
      rel="noreferrer noopener"
    >
      Get Started
    </Button>
    <div className="app-buttons">
      <AppButton
        logo="https://res.cloudinary.com/dwoc5fknz/image/upload/v1547558460/store/playstore.svg"
        store="Google Play"
        to="https://play.google.com/store/apps/details?id=co.busha.android"
        noMargin={true}
      />
      <AppButton
        logo="https://res.cloudinary.com/dwoc5fknz/image/upload/v1547558404/store/applestore.svg"
        store="App Store"
        to="https://itunes.apple.com/us/app/busha-buy-and-sell-bitcoins/id1450373493"
      />
    </div>
  </ButtonContainera>
)
const App = () => (
  <Container>
    <Title>Ready to buy bitcoin?</Title>
    <SubTitle>Register and get started with Busha now</SubTitle>
    <ButtonContainer>
      <ButtonCollection customWidth="auto" />
    </ButtonContainer>
  </Container>
)

export default App
