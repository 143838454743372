import styled from "styled-components"

import Img from "./Img"
import Button from "./Button"
import ImageText from "./ImageText"
import Container from "./Container"
import ButtonDetail from "./ButtonDetail"

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  padding-top: 35px;
`

Header.Img = Img
Header.Button = Button
Header.Container = Container
Header.ImageText = ImageText
Header.ButtonDetail = ButtonDetail

export default Header
