import styled, { keyframes } from "styled-components"
const shimmer = keyframes`
from {
    transform: translateX(-25%) translateY(25%);
}
to {
    transform: translateX(25%) translateY(-25%);
}
`
const Sparkle = styled.div`
  position: absolute;
  width: 500%;
  height: 500%;
  left: -200%;
  top: -200%;
  z-index: 0;
  filter: blur(8px);
  background: radial-gradient(
    circle at center center,
    rgb(255, 234, 229),
    rgb(255, 241, 230),
    rgb(255, 249, 227),
    rgb(233, 250, 243),
    rgb(232, 243, 252),
    rgb(234, 235, 253)
  );
  padding: 50px;
  border-radius: 1e8px;
  animation: 20000ms ease-in-out 0s infinite alternate none running ${shimmer};
`

export default Sparkle
