/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import TopSectionContainer from "../blocks/TopSectionContainer"
import BottomSection from "../blocks/BottomSection"
import GrandContainer from "../blocks/GrandContainer"
import Container from "../blocks/Container"
import DownloadApp from "./downloadApp"
import Sparkle from "../blocks/Sparkle"
import Main from "../blocks/Main"
import Discover from "./discover"
import Header from "./header"
import Footer from "./footer"
import Nav from "./nav"
import "./layout.css"

class Layout extends React.Component {
  state = {
    position: 0,
  }
  onGetScroll = ({ target: { documentElement } }) => {
    if (window.pageYOffset === undefined) {
      this.setState({ position: 0 })
      return 0
    } else {
      let sh, sy
      sy = window.pageYOffset + documentElement.clientHeight || 0
      sh = documentElement.offsetHeight || 0
      const position = (sy / sh) * 100
      this.setState({ position })
      return position
    }
  }
  componentDidMount() {
    window.addEventListener("scroll", this.onGetScroll)
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.onGetScroll)
  }
  render() {
    const { children } = this.props
    const showFloatingHeader =
      this.state.position > 22.232704402515722 ? true : false
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <GrandContainer header={false}>
              <TopSectionContainer header={showFloatingHeader}>
                <Container>
                  <Header siteTitle={data.site.siteMetadata.title} />
                  {showFloatingHeader && <Nav />}
                </Container>
              </TopSectionContainer>
              <TopSectionContainer pad={showFloatingHeader}>
                <Container>
                  <Discover />
                </Container>
              </TopSectionContainer>
              <Main>
                <Container>
                  <main>{children}</main>
                </Container>
              </Main>
            </GrandContainer>
            <BottomSection>
              <Sparkle />
              <DownloadApp />
              <Footer />
            </BottomSection>
          </>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
