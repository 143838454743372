import styled from "styled-components"
import React from "react"

import Header from "../blocks/Header"
import MobileDropDown from "./mobileDropDown"

export const BurgerButtonCont = styled.div`
  background-color: transparent;
  border-radius: 2px;
  width: 20px;
  height: 30px;
  padding: 0 6px;
  display: none;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  .bar {
    width: 20px;
    display: block;
    border: 1px solid #67c462;
    border-radius: 2px;
    transform: rotate(360deg);
    transition: all 0.3s;
  }
  .times {
    width: 20px;
    transform: rotate(360deg);
    transition: all 0.3s;
    margin-bottom: 0;
    max-width: unset;
  }
  @media (max-width: 768px) {
    display: flex;
  }
`
const MobileRight = ({ onHandleDropDown, dropDownOpen }) => (
  <BurgerButtonCont onClick={onHandleDropDown}>
    {!dropDownOpen ? (
      <>
        {" "}
        <span className="bar" />
        <span className="bar" />
        <span className="bar" />{" "}
      </>
    ) : (
      <img
        className="times"
        src="https://res.cloudinary.com/dwoc5fknz/image/upload/v1556493888/times.7eb2b02d.svg"
        alt="times icon"
      />
    )}
  </BurgerButtonCont>
)

class App extends React.Component {
  state = {
    dropDownOpen: false,
  }
  onHandleDropDown = () => {
    this.setState(({ dropDownOpen }) => ({
      dropDownOpen: !dropDownOpen,
    }))
  }
  render() {
    const { dropDownOpen } = this.state
    return (
      <Header>
        <Header.Container>
          <Header.Img
            src="https://uploads-ssl.webflow.com/61802c0e0fc3d12b496441b6/618036efb0d9db920b3a22b6_busha-logo.svg"
            alt="busha"
          />
          <Header.ImageText>Learn</Header.ImageText>
        </Header.Container>

        <Header.Container hideSmall={true}>
          <Header.ButtonDetail>Ready to buy crypto?</Header.ButtonDetail>
          <Header.Button
            href="https://busha.co/dashboard"
            target="_blank"
            rel="noreferrer noopener"
          >
            Get started
          </Header.Button>
        </Header.Container>
        <MobileRight
          dropDownOpen={dropDownOpen}
          onHandleDropDown={this.onHandleDropDown}
        />
        {dropDownOpen && (
          <MobileDropDown onHandleDropDown={this.onHandleDropDown} />
        )}
      </Header>
    )
  }
}

export default App
