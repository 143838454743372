import styled from "styled-components"

import MyButton from "../Header/Button"

const Button = styled(MyButton)`
  height: 50px;
  padding: 15px 36px;
`

export default Button
