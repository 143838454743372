import styled, { css } from "styled-components"

const Container = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    ${({ hideSmall }) =>
      hideSmall &&
      css`
        display: none;
      `}
  }
`
export default Container
