import React from "react"
import { Link } from "gatsby"

import Nav from "../blocks/Nav"
import { links } from "./constants"

const App = () => {
  const { hash } = window.location
  return (
    <Nav>
      {links.map(({ title, link }, key) => (
        <Link
          to={link}
          activeClassName="active"
          className={`/${hash}` === link ? "active" : ""}
          key={key}
        >
          {title}
        </Link>
      ))}
    </Nav>
  )
}

export default App
