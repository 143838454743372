import styled from "styled-components"

import Button from "./Button"
import Container from "./Container"
import Content from "./Content"
import Title from "./Title"
import Img from "./Img"

const Discover = styled.section`
  width: 100%;
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

Discover.Img = Img
Discover.Title = Title
Discover.Button = Button
Discover.Content = Content
Discover.Container = Container

export default Discover
