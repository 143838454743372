import styled from "styled-components"

const H1 = styled.h1`
  color: #1b314b;
  font-size: 48px;
  font-weight: 700;
  letter-spacing: -0.5px;
  line-height: 56px;
  width: 476px;
  text-align: left;
`

export default H1
