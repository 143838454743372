import styled from "styled-components"

const Container = styled.div`
  flex: ${({ flex }) => flex || 1};
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 768px) {
    flex: 1;
  }
`
export default Container
