import styled from "styled-components"

const Nav = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  padding-top: 25px;
  a {
    color: #637381;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    padding-bottom: 16px;
    text-decoration: none;
    transition: all 0.2s ease-in 0s;
    &:hover {
      transform: translateY(-1px);
      color: rgb(33, 43, 54);
    }
  }
  .active {
    border-bottom: 2px solid #67c462;
    padding: 0 14px 16px 5px;
    color: #67c462;
    &:hover {
      color: #637381;
      transform: translateY(0px);
    }
  }
  @media (max-width: 768px) {
    padding-top: 0;
    a {
      display: none;
    }
  }
`

export default Nav
