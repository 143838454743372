import React from "react"

import Discover from "../blocks/Discover"

const App = () => (
  <Discover>
    <Discover.Container flex="14854369">
      <Discover.Title>Cryptocurrency Unraveled</Discover.Title>
      <Discover.Content>
        Understand the basics of crypto and why we believe it is the future. Get
        answers to all your questions about cryptocurrency.
      </Discover.Content>
      <Discover.Button href="/#crypto_basics">Start exploring</Discover.Button>
    </Discover.Container>
    <Discover.Container flex="20000000">
      <Discover.Img
        src="https://res.cloudinary.com/dwoc5fknz/image/upload/v1556562410/busha-learn-hero-image.png"
        alt="showcase"
      />
    </Discover.Container>
  </Discover>
)

export default App
