import styled, { css } from "styled-components"

const Container = styled.div`
  width: 100%;
  background-image: url("https://res.cloudinary.com/dwoc5fknz/image/upload/v1556111866/clipped-rings.svg");
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: 54%;
  background-color: #fbfcff;
  ${({ header }) =>
    header &&
    css`
      background: none;
      /* position: fixed; */
      z-index: 100;
    `}
`
export default Container
