import styled from "styled-components"
import P from "../../elements/p"

const Content = styled(P)`
  color: #697788;
  font-size: 19px;
  font-weight: 600;
  line-height: 27px;
  max-width: 431px;
  text-align: left;
  margin-top: 8px;
  margin-bottom: 37px;
  @media (max-width: 768px) {
    max-width: 100%;
    font-size: 17px;
  }
`
export default Content
