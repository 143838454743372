import { Link } from "gatsby"
import { TimelineLite } from "gsap"
import React, { Fragment } from "react"

import { links } from "./constants"
import styled, { css } from "styled-components"

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: #f8fafe;
  left: 0;
  right: 0;
  z-index: 100;
  top: 76px;
  height: 100vh;
  & > .dropdown-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px 20px;
  }
`

const ButtonBox = styled.div`
  display: flex;
  justify-content: stretch;
  a {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: ${props => (props.clear ? "#F8FAFE" : "#67c462")};
    border-radius: 3px;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }

    color: ${props => (props.clear ? "#212B36" : "#F8FAFE")};
    font-size: 18px;
    font-weight: 400;
    line-height: 19px;
    text-align: center;
    ${props =>
      props["light-weight"] &&
      css`
        font-weight: 400;
      `};
  }
  .active {
    color: #67c462;
    font-weight: 800;
  }
`

// const Button = styled.a`
//   width: 100%;
//   padding-top: 20px;
//   padding-bottom: 20px;
//   background-color: ${props => (props.clear ? "#fff" : "#67c462")};
//   border-radius: 3px;
//   text-decoration: none;
//   &:hover {
//     text-decoration: none;
//     color: #ffffff;
//   }

//   color: ${props => (props.clear ? "#212B36" : "#ffffff")};
//   font-size: 18px;
//   font-weight: 400;
//   line-height: 19px;
//   text-align: center;
//   ${props =>
//     props["light-weight"] &&
//     css`
//       font-weight: 400;
//     `};
// `

// const LinkBox = styled.div`
//   display: flex;
//   justify-content: flex-start;
//   padding-top: 20px;
// `

// const DropDownLink = styled.a`
//   color: #637381;
//   font-size: 18px;
//   font-weight: 400;
//   line-height: 19px;
//   text-align: left;
// `

// const DropLink = styled(Link)`
//   color: #637381;
//   font-size: 18px;
//   font-weight: 400;
//   line-height: 19px;
//   text-align: left;
//   text-decoration: none;
// `

class App extends React.Component {
  constructor(props) {
    super(props)

    this.dropdownElement = React.createRef()
    this.dropdownTween = new TimelineLite().paused(true)
  }

  componentDidMount() {
    this.dropdownTween
      .to(
        this.dropdownElement.current,
        0.3,
        //   { y: 0, autoAlpha: 0 },
        { y: 20, visibility: "visible" },
        0
      )
      .play()
  }

  render() {
    const { hash } = window.location
    const { onHandleDropDown } = this.props
    return (
      <Container>
        <div ref={this.dropdownElement} className="dropdown-box">
          <Fragment>
            {links.map(({ title, link }, key) => (
              <ButtonBox clear light-weight key={key}>
                <Link
                  to={link}
                  activeClassName="active"
                  onClick={onHandleDropDown}
                  className={`/${hash}` === link ? "active" : ""}
                >
                  {title}
                </Link>
              </ButtonBox>
            ))}
          </Fragment>
        </div>
      </Container>
    )
  }
}

export default App
