import React, { Fragment } from "react"
import styled from "styled-components"

// import Logo2 from "../images/logo"
import { Link } from "gatsby"

export const container = `
  padding: 1.5rem;
  max-width: 75rem;
  margin: 0 auto;
`
const FooterStyled = styled.div`
  ${container} background-color: transparent;
  display: flex;
  justify-content: space-between;
  padding-top: 83px;
  padding-bottom: 83px;
  font-family: "MarkPro";
  p {
    margin: 0;
  }
  .logoCont {
    img {
      width: 39px;
    }
    @media (max-width: 768px) {
      display: none;
    }
  }

  p:not(:last-child) {
    padding-bottom: 16px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: space-evenly;
    margin: 0;
    padding: 0;

    p {
      padding-bottom: 0;
    }

    p:not(:last-child) {
      padding-bottom: 16px;
    }

    div {
      padding-bottom: 16px;
    }
  }
`

const FooterWrapper = styled.div`
  width: 630px;
  margin: 0 auto;
  z-index: 1;
  position: relative;
  @media (max-width: 768px) {
    width: 90%;
    padding-top: 40px;
  }
`

const FooterLink = styled.a`
  color: ${({ color }) => color || "rgb(34, 34, 34)"};
  font-weight: 800;
  font-size: 16px;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  &:hover {
    opacity: 0.5;
  }
`

// const ExtendedLink = styled(Link)`
//   color: ${({ color }) => color || "rgb(34, 34, 34)"};
//   font-size: 16px;
//   text-decoration: none;
//   font-weight: 800;
//   transition: all 0.1s ease-in-out;
//   &:hover {
//     opacity: 0.5;
//   }
// `

const HiringBorder = styled.span`
  width: 42px;
  height: 2px;
  background: #67c462;
  border-radius: 2px;
  display: block;
`

const Copyright = styled.div`
  text-align: center;
  color: #657583;
  padding-bottom: 76px;

  @media (max-width: 768px) {
    text-align: left;
    margin-top: 24px;
    margin-bottom: 12px;
  }
`

const Footer = () => (
  <Fragment>
    <FooterWrapper>
      <FooterStyled>
        <div className="logoCont">
          <Link to="/">
            {/* <Logo2 /> */}
            <img
              src="https://res.cloudinary.com/dwoc5fknz/image/upload/v1636510537/Logos/Oxford_Blue_on_Emerald_BG.png"
              alt="Busha"
            />
          </Link>
        </div>

        <div>
          <p>
            <FooterLink
              href="http://support.busha.co/"
              target="_blank"
              rel="noreferrer noopener"
            >
              Help Center
            </FooterLink>
          </p>

          <p>
            <FooterLink
              href="http://support.busha.co/"
              target="_blank"
              rel="noreferrer noopener"
            >
              FAQ
            </FooterLink>
          </p>

          <p>
            <FooterLink
              href="https://busha.co/legal/terms-of-use"
              target="_blank"
              rel="noreferrer noopener"
            >
              Terms of use
            </FooterLink>
          </p>

          <p>
            <FooterLink
              href="https://busha.co/legal/privacy-policy"
              target="_blank"
              rel="noreferrer noopener"
            >
              Privacy Policy
            </FooterLink>
          </p>

          <p>
            <FooterLink
              href="https://busha.co/legal/aml-kyc-policy"
              target="_blank"
              rel="noreferrer noopener"
            >
              AML/KYC policy
            </FooterLink>
          </p>
        </div>

        <div>
          <p>
            <FooterLink href="https://facebook.com/getbusha" target="_blank">
              Facebook
            </FooterLink>
          </p>
          <p>
            <FooterLink href="https://twitter.com/getbusha" target="_blank">
              Twitter
            </FooterLink>
          </p>
          <p>
            <FooterLink href="https://instagram.com/getbusha" target="_blank">
              Instagram
            </FooterLink>
          </p>
          <p>
            <FooterLink href="https://medium.com/busha" target="_blank">
              Blog
            </FooterLink>
          </p>
          <p>
            <FooterLink href="https://t.me/getbusha" target="_blank">
              Telegram
            </FooterLink>
          </p>
        </div>

        <div>
          <p>
            <FooterLink href="mailto:hello@busha.co">hello@busha.co</FooterLink>
          </p>
          <p>
            <FooterLink color="#67C462" href="#">
              We are hiring!
            </FooterLink>
            <HiringBorder />
          </p>
        </div>
      </FooterStyled>
      <Copyright>
        &copy; {`${new Date().getFullYear()}`} Busha. All rights reserved.
      </Copyright>
    </FooterWrapper>
  </Fragment>
)

export default Footer
