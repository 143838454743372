import styled from "styled-components"

const P = styled.p`
  color: #222d4f;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
`
export default P
