import styled, { keyframes } from "styled-components"

const placeHolderShimmer = keyframes`
  from {
    background-position: -1000px 0
  }
  to {
    background-position: 1000px 0
  }
`

const Container = styled.div`
  /* width: 100%;
  background: radial-gradient(
    circle at center center,
    rgb(255, 234, 229),
    rgb(255, 241, 230),
    rgb(255, 249, 227),
    rgb(233, 250, 243),
    rgb(232, 243, 252),
    rgb(234, 235, 253)
  );
  animation: ${placeHolderShimmer} 30000ms linear infinite;
  background-size: 400%; */
  position:relative;
  overflow: hidden;
  background: rgb(232, 247, 247);
`
export default Container
